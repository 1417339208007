import {
  Add,
  Check,
  Clear,
  Close,
  FactCheck,
  PlayArrow,
  QuestionMark,
  Remove,
  Replay,
  Schedule,
} from "@mui/icons-material";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Collapse,
  Dialog,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Slide,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { TitleContext } from "../common/TitleProvier";

export default function MathTest() {
  const [additionRange, setAdditionRange] = React.useState([
    [1, 100],
    [1, 100],
  ]);
  const [subtractionRange, setSubtractionRange] = React.useState([
    [1, 100],
    [1, 100],
  ]);
  const [multiplicationRange, setMultiplicationRange] = React.useState([
    [1, 100],
    [1, 10],
  ]);
  const [divisionRange, setDivisionRange] = React.useState([
    [1, 100],
    [1, 10],
  ]);

  const [addition, setAddition] = React.useState(true);
  const [subtraction, setSubtraction] = React.useState(true);
  const [multiplication, setMultiplication] = React.useState(true);
  const [division, setDivision] = React.useState(true);

  const [timer, setTimer] = React.useState(30);
  const [question, setQuestion] = React.useState(10);

  const [reqiresCorrect, setRequiresCorrect] = React.useState(false);

  const [type, setType] = React.useState("timer");

  const { setTitle } = React.useContext(TitleContext);

  React.useEffect(() => {
    setTitle("Arithmetic Game");
  }, [setTitle]);

  const [gameOpen, setGameOpen] = React.useState(false);
  const [gameSeed, setGameSeed] = React.useState(0);

  return (
    <Box>
      <Stack direction="column" spacing={2} alignItems={"center"}>
        <ArithmeticPicker
          name="Addition"
          icon={<Add />}
          enabled={addition}
          toggleEnabled={() => setAddition(!addition)}
          range={additionRange}
          setRange={setAdditionRange}
        />
        <ArithmeticPicker
          name="Subtraction"
          icon={<Remove />}
          enabled={subtraction}
          toggleEnabled={() => setSubtraction(!subtraction)}
          range={subtractionRange}
          setRange={setSubtractionRange}
        />
        <ArithmeticPicker
          name="Multiplication"
          icon={<Clear />}
          enabled={multiplication}
          toggleEnabled={() => setMultiplication(!multiplication)}
          range={multiplicationRange}
          setRange={setMultiplicationRange}
        />
        <ArithmeticPicker
          name="Division"
          icon={
            <span
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                padding: "0 0.5rem",
              }}
            >
              /
            </span>
          }
          enabled={division}
          toggleEnabled={() => setDivision(!division)}
          range={divisionRange}
          setRange={setDivisionRange}
        />
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <ToggleButtonGroup value={type} exclusive>
            <ToggleButton value={"timer"} onClick={() => setType("timer")}>
              <Schedule sx={{ mr: 1 }} />
              Timer
            </ToggleButton>
            <ToggleButton
              value={"question"}
              onClick={() => setType("question")}
            >
              <QuestionMark /> Questions
            </ToggleButton>
          </ToggleButtonGroup>
          <Tooltip title="Require correct answer to continue">
            <Fab
              variant="extended"
              onClick={() => setRequiresCorrect(!reqiresCorrect)}
              color={reqiresCorrect ? "success" : "error"}
            >
              {reqiresCorrect ? <Check /> : <Clear />} Correct Answer
            </Fab>
          </Tooltip>
        </Stack>
        <Collapse in={type === "timer"}>
          <TextField
            label="Timer"
            value={timer}
            onChange={(e) => setTimer(e.target.value)}
            size="small"
            variant="standard"
            type="number"
            onScroll={(e) => e.target.blur()}
            inputProps={{ min: 1, max: 999 }}
          />
        </Collapse>
        <Collapse in={type === "question"}>
          <TextField
            label="Questions"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            size="small"
            variant="standard"
            type="number"
            onScroll={(e) => e.target.blur()}
            inputProps={{ min: 1 }}
          />
        </Collapse>
        <Button
          variant="contained"
          color="success"
          startIcon={<PlayArrow />}
          disabled={
            !addition &&
            !subtraction &&
            !multiplication &&
            !division &&
            timer <= 0 &&
            question <= 0
          }
          onClick={() => setGameOpen(true)}
        >
          Start
        </Button>
      </Stack>

      <Dialog open={gameOpen} fullScreen>
        <Game
          key={gameSeed}
          setSeed={setGameSeed}
          addition={addition}
          subtraction={subtraction}
          multiplication={multiplication}
          division={division}
          additionRange={additionRange}
          subtractionRange={subtractionRange}
          multiplicationRange={multiplicationRange}
          divisionRange={divisionRange}
          type={type}
          time={timer}
          questions={question}
          setGameOpen={setGameOpen}
          requiresCorrect={reqiresCorrect}
        />
      </Dialog>
    </Box>
  );
}

function ArithmeticPicker({
  name,
  enabled,
  toggleEnabled,
  icon,
  range,
  setRange,
}) {
  const [r1, setR1] = React.useState(range[0][0]);
  const [r2, setR2] = React.useState(range[0][1]);
  const [r3, setR3] = React.useState(range[1][0]);
  const [r4, setR4] = React.useState(range[1][1]);

  React.useEffect(() => {
    setTimeout(() => {
      setRange([
        [r1, r2],
        [r3, r4],
      ]);
    }, 1000);
  }, [r1, r2, r3, r4, setRange]);

  return (
    <Paper
      variant={enabled ? "elevation" : "outlined"}
      elevation={2}
      sx={{ p: 1 }}
    >
      <Button
        startIcon={icon}
        variant={enabled ? "contained" : "outlined"}
        onClick={toggleEnabled}
      >
        {name}
      </Button>
      <Collapse in={enabled}>
        <Stack direction="row" alignItems={"baseline"} sx={{ p: 2 }}>
          <TextField
            label="Min"
            value={r1}
            onChange={(e) => setR1(e.target.value)}
            size="small"
            variant="standard"
            type="number"
            onScroll={(e) => e.target.blur()}
            inputProps={{ min: 1 }}
            InputProps={{ startAdornment: "(", endAdornment: ")-" }}
          />
          <TextField
            label="Max"
            value={r2}
            onChange={(e) => setR2(e.target.value)}
            size="small"
            variant="standard"
            type="number"
            onScroll={(e) => e.target.blur()}
            inputProps={{ min: 1 }}
            InputProps={{ startAdornment: "(", endAdornment: ")" }}
          />
          <Typography variant="body1">{icon}</Typography>
          <TextField
            label="Min"
            value={r3}
            onChange={(e) => setR3(e.target.value)}
            size="small"
            variant="standard"
            type="number"
            onScroll={(e) => e.target.blur()}
            inputProps={{ min: 1 }}
            InputProps={{ startAdornment: "(", endAdornment: ")-" }}
          />
          <TextField
            label="Max"
            value={r4}
            onChange={(e) => setR4(e.target.value)}
            size="small"
            variant="standard"
            type="number"
            onScroll={(e) => e.target.blur()}
            inputProps={{ min: 1 }}
            InputProps={{ startAdornment: "(", endAdornment: ")" }}
          />
        </Stack>
      </Collapse>
    </Paper>
  );
}

function Game({
  addition,
  subtraction,
  multiplication,
  division,
  additionRange,
  subtractionRange,
  multiplicationRange,
  divisionRange,
  type,
  time,
  questions,
  setGameOpen,
  setSeed,
  requiresCorrect,
}) {
  const saneTime = time < 0 ? 1 : time > 999 ? 999 : time;
  const [timer, setTimer] = React.useState(
    type === "timer" ? saneTime * 10 : 0
  );
  const [currentQuestion, setCurrentQuestion] = React.useState(0);
  const [currentCorrect, setCurrentCorrect] = React.useState(0);
  const [currentIncorrect, setCurrentIncorrect] = React.useState(0);
  const [currentScore, setCurrentScore] = React.useState(0);
  const [currentStreak, setCurrentStreak] = React.useState(0);
  const [currentBestStreak, setCurrentBestStreak] = React.useState(0);
  const [gameOver, setGameOver] = React.useState(false);

  React.useEffect(() => {
    document.getElementById("answerBox")?.focus();
    const countDown = () => {
      if (type === "timer" && timer > 0) {
        setTimer(timer - 1);
      } else if (!gameOver) {
        setTimer(timer + 1);
      }
    };
    const interval = setInterval(() => {
      countDown();
    }, 100);
    return () => clearInterval(interval);
  }, [timer, type, gameOver]);

  React.useEffect(() => {
    if (type === "timer") {
      if (timer <= 0) {
        setGameOver(true);
      }
    } else if (currentQuestion >= questions) {
      setGameOver(true);
    }
  }, [timer, type, questions, currentQuestion]);

  const [questionList, setQuestionList] = React.useState([]);
  const [answer, setAnswer] = React.useState("");

  React.useEffect(() => {
    const generateQuestion = () => {
      let q = [];
      if (addition) {
        q.push({
          type: "addition",
          Symbol: "+",
          range: additionRange,
        });
      }
      if (subtraction) {
        q.push({
          type: "subtraction",
          Symbol: "-",
          range: subtractionRange,
        });
      }
      if (multiplication) {
        q.push({
          type: "multiplication",
          Symbol: "x",
          range: multiplicationRange,
        });
      }
      if (division) {
        q.push({
          type: "division",
          Symbol: "/",
          range: divisionRange,
        });
      }
      let question = q[Math.floor(Math.random() * q.length)];
      let r1 = Math.floor(
        Math.random() * (question.range[0][1] - question.range[0][0] + 1) +
          question.range[0][0]
      );
      let r2 = Math.floor(
        Math.random() * (question.range[1][1] - question.range[1][0] + 1) +
          question.range[1][0]
      );
      let a = 0;
      switch (question.type) {
        case "addition":
          a = r1 + r2;
          break;
        case "subtraction":
          do {
            r1 = Math.floor(
              Math.random() *
                (question.range[0][1] - question.range[0][0] + 1) +
                question.range[0][0]
            );
            r2 = Math.floor(
              Math.random() *
                (question.range[1][1] - question.range[1][0] + 1) +
                question.range[1][0]
            );
          } while (r1 < r2);
          a = r1 - r2;
          break;
        case "multiplication":
          a = r1 * r2;
          break;
        case "division":
          do {
            r1 = Math.floor(
              Math.random() *
                (question.range[0][1] - question.range[0][0] + 1) +
                question.range[0][0]
            );
            for (let i = question.range[1][1]; i >= question.range[1][0]; i--) {
              if (r1 % i === 0 && i !== r1) {
                r2 = i;
                break;
              }
            }
            a = r1 / r2;
          } while (
            !(
              Number.isInteger(a) &&
              r2 <= question.range[1][1] &&
              r2 >= question.range[1][0] &&
              r1 !== r2 &&
              r2 !== 1
            )
          );

          // Now 'a' is a positive integer and 'r2' is within [x, y]
          break;
        default:
          break;
      }
      setQuestionList([
        ...questionList,
        {
          question: `${r1} ${question.Symbol} ${r2}`,
          answer: a,
          userAnswer: null,
          correct: null,
        },
      ]);
    };

    if (currentQuestion < questions) {
      generateQuestion();
    }
  }, [
    currentQuestion,
    questions,
    addition,
    subtraction,
    multiplication,
    division,
    additionRange,
    subtractionRange,
    multiplicationRange,
    divisionRange,
    questionList,
  ]);

  const [flashCorrect, setFlashCorrect] = React.useState(false);
  const [flashIncorrect, setFlashIncorrect] = React.useState(false);

  React.useEffect(() => {
    if (flashCorrect) {
      setTimeout(() => {
        setFlashCorrect(false);
      }, 1000);
    }
  }, [flashCorrect]);

  React.useEffect(() => {
    if (flashIncorrect) {
      setTimeout(() => {
        setFlashIncorrect(false);
      }, 1000);
    }
  }, [flashIncorrect]);

  const answerQuestion = () => {
    const parsedAnswer = parseFloat(answer.trim());
    let q = questionList;
    const correct = parsedAnswer === q[currentQuestion].answer;

    if (correct) {
      setCurrentCorrect((prevCorrect) => prevCorrect + 1);
      setCurrentStreak((prevStreak) => prevStreak + 1);

      if (currentStreak + 1 > currentBestStreak) {
        setCurrentBestStreak((prevBestStreak) => prevBestStreak + 1);
      }

      setCurrentScore((prevScore) => prevScore + 1);
      setFlashCorrect(true);
    } else {
      if (requiresCorrect) {
        setCurrentStreak(0);
        setFlashIncorrect(true);
      } else {
        setCurrentIncorrect((prevIncorrect) => prevIncorrect + 1);
        setCurrentStreak(0);
        setFlashIncorrect(true);
      }
    }

    if (correct || !requiresCorrect) {
      // save to questions list
      q[currentQuestion].userAnswer = parsedAnswer;
      q[currentQuestion].correct = correct;
      setQuestionList(q);
      //move to next question
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      setAnswer("");
    }
  };

  let timeToUse = type === "timer" ? saneTime : timer / 10;

  const isMobile = window.innerWidth < 600;
  const [seeQuestions, setSeeQuestions] = React.useState(false);

  return (
    <Box>
      <Tooltip title="Close">
        <IconButton
          onClick={() => setGameOpen(false)}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1000,
          }}
        >
          <Close />
        </IconButton>
      </Tooltip>
      <Stack
        direction="column"
        alignItems={"center"}
        sx={{
          position: "absolute",
          top: 8,
          left: 0,
          right: 0,
        }}
      >
        <Typography variant="h1">{(timer / 10).toFixed(1)}</Typography>
        <Typography variant="h4">#{currentQuestion}</Typography>
      </Stack>

      <Stack
        direction="column"
        spacing={0}
        alignItems={"center"}
        sx={{
          position: "absolute",
          top: 8,
          left: 8,
        }}
      >
        <Typography variant="h5">Score: {currentScore}</Typography>
        <Typography variant="h6">Streak: {currentStreak}</Typography>
      </Stack>

      <Box
        sx={{
          position: "absolute",
          left: 64,
          top: isMobile ? "30%" : "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Slide direction="right" in={flashCorrect}>
          <Alert severity="success" sx={{ position: "absolute" }}>
            Correct
          </Alert>
        </Slide>
        <Slide direction="right" in={flashIncorrect}>
          <Alert severity="error">Wrong</Alert>
        </Slide>
      </Box>

      <Stack
        direction="column"
        justifyContent={"center"}
        sx={{ height: "100vh" }}
      >
        <Paper
          variant="outlined"
          sx={{
            py: 2,
            ...(flashCorrect && {
              borderColor: (theme) => theme.palette.success.main,
            }),
            ...(flashIncorrect && {
              borderColor: (theme) => theme.palette.error.main,
            }),
          }}
        >
          <Stack
            direction="column"
            spacing={2}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography variant="h4">
              {questionList[currentQuestion]?.question}
            </Typography>
            <form onSubmit={(e) => e.preventDefault()}>
              <Stack direction="column" spacing={2} alignItems={"center"}>
                <TextField
                  id="answerBox"
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  type="number"
                  autoFocus
                  disabled={gameOver}
                />
                <Button
                  variant="contained"
                  color="success"
                  type="submit"
                  onClick={answerQuestion}
                  disabled={answer === "" || gameOver}
                >
                  Submit
                </Button>
              </Stack>
            </form>
          </Stack>
        </Paper>
      </Stack>
      <Backdrop open={gameOver} sx={{ bgcolor: "rgba(0,0,0,0.9)" }}>
        <Stack direction="column" spacing={2} alignItems={"center"}>
          <Typography variant="h1">Game Over</Typography>

          <Typography variant="h2">
            Time{type === "questions" && " Taken"}: {timeToUse}s
          </Typography>

          <Typography variant="h2">Score: {currentScore}</Typography>
          <Typography variant="h4">Correct: {currentCorrect}</Typography>
          <Typography variant="h4">Incorrect: {currentIncorrect}</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h3">
              Total Questions: {currentCorrect + currentIncorrect}
            </Typography>
            <IconButton color="info" onClick={() => setSeeQuestions(true)}>
              <FactCheck />
            </IconButton>
          </Stack>
          <Typography variant="h4">Streak: {currentBestStreak}</Typography>
          <Typography variant="h5">
            Average: {(timeToUse / currentQuestion).toFixed(2)}s per question
          </Typography>
          <Typography variant="h5">
            Accuracy: {((currentCorrect / currentQuestion) * 100).toFixed(2)}%
          </Typography>
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Button
              variant="contained"
              color="success"
              onClick={() => setSeed(Math.random())}
              startIcon={<Replay />}
            >
              Play Again
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => setGameOpen(false)}
              startIcon={<Close />}
            >
              Quit
            </Button>
          </Stack>
        </Stack>

        <QuestionList
          questions={questionList.slice(0, currentQuestion)}
          open={seeQuestions}
          setOpen={setSeeQuestions}
        />
      </Backdrop>
    </Box>
  );
}

function QuestionList({ questions, open, setOpen }) {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Paper elevation={0}>
        <List divider>
          {questions.map((q, i) => (
            <ListItem key={i}>
              <Stack direction="row" spacing={2} alignItems={"center"}>
                <ListItemText
                  sx={{
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.divider}`,
                    pr: 2,
                  }}
                >
                  {i}
                </ListItemText>
                <ListItemIcon>
                  {q.correct ? (
                    <Check color="success" />
                  ) : (
                    <Clear color="error" />
                  )}
                </ListItemIcon>
                <ListItemText>
                  {q.question} = {q.answer}
                </ListItemText>
                <ListItemText
                  sx={{
                    borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
                    pl: 2,
                  }}
                >
                  {q.userAnswer}
                </ListItemText>
              </Stack>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Dialog>
  );
}

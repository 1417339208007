import React from "react";
import { Helmet } from "react-helmet";
import {
  Typography,
  Card,
  Grid,
  CardContent,
  CardActions,
  Button,
  Paper,
  Link,
  Slide,
  Collapse,
  Stack,
  CardMedia,
  Skeleton,
  Box,
  Fade,
  Dialog,
  DialogContent,
  DialogContentText,
  Fab,
  useMediaQuery,
  SvgIcon,
} from "@mui/material";

import { Download, InfoOutlined, OpenInNew } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";
import LightenDarkenColor from "./ColourBrigthness";
import appList from "../../data/appList";

export default function Main() {
  return (
    <Box sx={{ p: 2, px: 5 }}>
      <Helmet>
        <title>Skulldorom.com | Apps</title>
        <meta name="Apps" content="Apps by Skulldorom" />
      </Helmet>
      <Collapse in={true}>
        <Grid container spacing={3} sx={{ py: 5 }}>
          {appList.map((program, index) => (
            <Grid item xs={12} sm={6} md={4} xl={3} key={index}>
              <ProgramCard data={program} animate={true} />
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </Box>
  );
}

function ProgramCard({ data, animate }) {
  const [hover, setHover] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [imgLoading, setImgLoading] = React.useState(true);

  const handleImageLoad = () => {
    setImgLoading(false);
  };

  const ref = React.useRef(null);
  const [height, setHeight] = React.useState(180);

  React.useLayoutEffect(() => {
    const calc = (ref?.current?.offsetWidth / 16) * 9 || 180;
    setHeight(calc);
  }, []);

  const HoverText = () => (
    <Box
      sx={{
        display: imgLoading ? "none" : "block",
        position: "absolute",
        marginLeft: 2,
        marginTop: 2,
        maxWidth: { xs: "190px", md: "200px", lg: "600px", xl: "600px" },
      }}
    >
      <Fade in={!hover} timeout={1000}>
        <Typography
          variant="h5"
          color="white"
          sx={{
            fontWeight: "bold",
            textShadow: "1px 1px 5px black",
          }}
        >
          {data.name}
        </Typography>
      </Fade>
    </Box>
  );

  return (
    <>
      <Slide in={animate} direction="up">
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            transform: hover ? "scale(1.15) !important" : "scale(1)",
            zIndex: hover ? 1 : 0,
          }}
          raised={hover}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={handleOpen}
        >
          {imgLoading && (
            <Skeleton
              ref={ref}
              variant="rectangular"
              width="100%"
              height={height}
            />
          )}
          <CardMedia
            component="img"
            height="150px"
            width="100%"
            image={data.img}
            alt={data.name}
            sx={{ display: imgLoading ? "none" : "block" }}
            onLoad={handleImageLoad}
          />
          <HoverText />
          <Collapse in={hover}>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                {data.name}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {data.description}
              </Typography>
            </CardContent>
            <CardActions sx={{ p: 0 }}>
              <Paper
                elevation={0}
                sx={{
                  width: "100%",
                  p: 1,
                }}
              >
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    startIcon={<InfoOutlined />}
                    onClick={handleOpen}
                  >
                    More Info
                  </Button>
                  <Button
                    variant="contained"
                    component={Link}
                    href={data.url}
                    target={data.newTab ? "_blank" : ""}
                    startIcon={data.download ? <Download /> : <OpenInNew />}
                  >
                    {data.download ? "Download" : "Go to"}
                  </Button>
                </Stack>
              </Paper>
            </CardActions>
          </Collapse>
        </Card>
      </Slide>

      <ProgramDialog open={open} onClose={handleClose} data={data} />
    </>
  );
}

function ProgramDialog({ open, onClose, data }) {
  const theme = useTheme();
  const fColor =
    theme.palette.mode === "dark"
      ? LightenDarkenColor(theme.palette.background.paper, 30)
      : theme.palette.background.paper;

  const [imgLoading, setImgLoading] = React.useState(true);

  const handleImageLoad = () => {
    setImgLoading(false);
  };

  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    let currentWidth = theme.breakpoints.values.md;
    if (lessThanSmall)
      currentWidth = document.documentElement.clientWidth * 0.84;
    setHeight((currentWidth / 16) * 9);
  }, [lessThanSmall, theme]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Fab
        onClick={onClose}
        size="small"
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          backgroundColor: "rgba(0,0,0,0.3)",
          color: "white",
          "&:hover": {
            backgroundColor: "rgba(255,255,255,0.3)",
          },
        }}
      >
        <CloseIcon />
      </Fab>
      {imgLoading && (
        <Skeleton variant="rectangular" width="100%" height={height} />
      )}
      <CardMedia
        component="img"
        height={height}
        width="100%"
        image={data.img}
        alt={data.name}
        sx={{ display: imgLoading ? "none" : "block" }}
        onLoad={handleImageLoad}
      />
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: height,
          background: `linear-gradient(0deg, ${fColor}, transparent 50%)`,
        }}
      />
      <Box sx={{ pt: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ px: 3 }}>
          {data.name}
          <Button
            variant="contained"
            component={Link}
            href={data.url}
            target={data.newTab ? "_blank" : ""}
            startIcon={data.download ? <Download /> : <OpenInNew />}
            sx={{ ml: 2 }}
          >
            {data.download ? "Download" : "Go to"}
          </Button>
        </Typography>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ display: "flex", alignItems: "center" }}
              >
                About <SvgIcon component={InfoOutlined} sx={{ ml: 1 }} />
              </Typography>
              <DialogContentText gutterBottom>
                {data.description}
              </DialogContentText>
              {data.links &&
                data.links.map((link) => (
                  <Link key={link.url} href={link.url} color="secondary">
                    {link.name}: {link.url}
                  </Link>
                ))}
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

import React from "react";
import { Routes, Route } from "react-router-dom";

import LandingPage from "./pages/Landing";
import MarvelSnapCacheSim from "./MarvelSnapCacheSim/MarvelSnapCacheSim";
import MathTest from "./MathTest/MathTest";

export default function Map() {
  return (
    <Routes>
      <Route path="*" element={<LandingPage />} />
      <Route path="/" element={<LandingPage />} />
      <Route path="/SnapCacheSimulator" element={<MarvelSnapCacheSim />} />
      <Route path="/Arithmetic" element={<MathTest />} />
    </Routes>
  );
}

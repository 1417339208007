import weightedRandom from "./weightedRandom.js";

const CollectorReserve = {
  name: "CollectorReserve",
  rewards: {
    card: {
      s3: 22.25,
      s4: 2.5,
      s5: 0.25,
    },
    credits: {
      200: 0,
      300: 10,
      350: 0,
      400: 5,
    },
    gold: {
      150: 0,
      200: 10,
    },
    tokens: {
      100: 25,
    },
    cosmetic: {
      variant: 10,
      avatar: 7.5,
      title: 7.5,
    },
  },
};

export function getReward(
  s3Complete = false,
  canGets3 = false,
  canGets4 = false,
  canGetTokens = false
) {
  let caches = CollectorReserve;

  let rewards = caches.rewards;

  let rewardNamesMain = Object.keys(rewards);
  let rewardNames = [];
  let rewardWeights = [];
  let rewardLookup = [];

  for (let i = 0; i < rewardNamesMain.length; i++) {
    for (let j = 0; j < Object.keys(rewards[rewardNamesMain[i]]).length; j++) {
      rewardNames.push(
        Object.keys(rewards[rewardNamesMain[i]])[j] + " " + rewardNamesMain[i]
      );
      rewardWeights.push(Object.values(rewards[rewardNamesMain[i]])[j]);
      rewardLookup.push({
        type: rewardNamesMain[i],
        value: Object.keys(rewards[rewardNamesMain[i]])[j],
      });
    }
  }

  let foundReward = null;
  while (true) {
    foundReward = weightedRandom(rewardNames, rewardWeights);

    if (foundReward.item.includes("s4 card") && !canGets4) continue;
    else break;
  }

  while (true) {
    if (foundReward.item.includes("s3 card") && !canGets3) {
      foundReward = weightedRandom(rewardNames, rewardWeights);
    } else break;
  }

  if (foundReward.item.includes("tokens")) {
    let foundValue = weightedRandom(
      [200, 300, 400, 500, 600],
      [20, 20, 20, 20, 20]
    );
    foundReward.value = foundValue.item;
  }

  while (true) {
    if (foundReward.item.includes("tokens") && !canGetTokens) {
      foundReward = weightedRandom(rewardNames, rewardWeights);
    } else break;
  }

  if (s3Complete && foundReward.item.includes("s3 card")) {
    return { type: "tokens", value: 100 };
  }

  return rewardLookup[foundReward.index];
}

const programList = [
  {
    name: "Marvel Snap Cache Companion",
    description:
      "Track your current cache cylce and when you will get your next pool 3 or pool 4 card as well as simulate opening cache's in Marvel Snap",
    url: "/SnapCacheSimulator",
    img: "/MarvelSnap/SkullSnap.png",
  },
  {
    name: "Arithmetic Game",
    description: "Test your arithmetic skills with this simple game",
    url: "/Arithmetic",
    img: "/MathTest/MathTest.jpg",
  },
];

export default programList;

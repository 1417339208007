import React from "react";
import AppList from "../common/components/AppList";

export default function Landing() {
  return (
    <>
      <AppList />
    </>
  );
}

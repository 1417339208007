import React from "react";

export const TitleContext = React.createContext({
  title: "",
  setTitle: null,
});

const TitleProvider = (props) => {
  const { children } = props;
  const [titleValue, setTitleValue] = React.useState("");

  const contextValue = {
    title: titleValue,
    setTitle: setTitleValue,
  };

  return (
    <TitleContext.Provider value={contextValue}>
      {children}
    </TitleContext.Provider>
  );
};

export default TitleProvider;

import React from "react";
import Routes from "./Routes";
import {
  AppBar,
  Box,
  CardActionArea,
  Collapse,
  Fade,
  Paper,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Copyright from "./common/Copyright";
import { Link, useLocation } from "react-router-dom";
import TitleProvider, { TitleContext } from "./common/TitleProvier";

// This page will handle the other pages

//Defining Meta

function App() {
  return <Main />;
}

export default App;

function Main(props) {
  const currentLoc = useLocation();
  const [showLand, setshowLand] = React.useState(currentLoc.pathname === "/");
  const [showApp, setshowApp] = React.useState(currentLoc.pathname !== "/");

  React.useEffect(() => {
    if (!showLand) {
      setTimeout(() => {
        setshowApp(true);
      }, 1000);
    }
  }, [showLand]);

  return (
    <>
      <Collapse in={showLand} timeout={2000}>
        <Paper elevation={0}>
          <Box sx={{ m: "auto", height: "100dvh" }}>
            <Stack
              spacing={2}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <img src="/Icon3.png" alt="Logo" height={"300"} />
              <Typography variant="h2" component="h1" color="primary">
                Skulldorom
              </Typography>
              <Typography variant="h5">Hobby Apps and cool stuff</Typography>
            </Stack>
          </Box>
        </Paper>
      </Collapse>
      <Fade in={showLand} timeout={500}>
        <CardActionArea
          onClick={() => setshowLand(false)}
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SvgIcon
            sx={{
              fontSize: "3rem",
            }}
          >
            <KeyboardArrowDownIcon />
          </SvgIcon>
        </CardActionArea>
      </Fade>
      <TitleProvider>
        <Base showLand={showLand} showApp={showApp} currentLoc={currentLoc} />
      </TitleProvider>
    </>
  );
}

function Base({ showLand, showApp, currentLoc }) {
  const { title, setTitle } = React.useContext(TitleContext);

  React.useEffect(() => {
    if (currentLoc.pathname === "/") setTitle("Home");
  }, [currentLoc, setTitle]);

  return (
    <Box sx={{ display: showLand && "none" }}>
      <Fade in={showApp} timeout={1000}>
        <AppBar position="fixed">
          <Stack
            direction={"row"}
            spacing={1}
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              component={Link}
              to="/"
              sx={{
                textDecoration: "none",
                pl: 2,
              }}
            >
              <img src="/Icon3.png" alt="Logo" height={"64px"} />
              <Typography variant="h6" color={"white"}>
                Skulldorom.com
              </Typography>
            </Stack>
            <Typography variant="h6" color={"white"}>
              {title}
            </Typography>
            <Box />
            <Box />
          </Stack>
        </AppBar>
      </Fade>
      <Box
        minHeight={"100dvh"}
        sx={{
          pt: 10,
          pb: 10,
        }}
      >
        <Routes />
      </Box>
      <Fade in={!showLand} timeout={500}>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "background.paper",
          }}
        >
          <Copyright />
        </Box>
      </Fade>
    </Box>
  );
}

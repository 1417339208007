import React from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Grid,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Chip,
  Divider,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { getReward } from "./common/main";
import weightedRandom from "./common/weightedRandom";
import { TitleContext } from "../common/TitleProvier";

export default function Main() {
  const { setTitle } = React.useContext(TitleContext);

  React.useEffect(() => {
    setTitle("Marvel Snap Companion");
  }, [setTitle]);

  return (
    <>
      <Helmet>
        <title>Skulldorom.com | Marvel Snap Cache Companion</title>
        <meta
          name="Marvel Snap Cache Companion"
          content="Marvel Snap Cache Companion by Skulldorom"
        />
      </Helmet>
      <Box
        sx={{
          px: 2,
        }}
      >
        <MarvelSnapBlockTracker />
        <Typography variant="overline" color={"grey"}>
          Works with Version 13.13.2+0 of Marvel Snap
        </Typography>
      </Box>
    </>
  );
}

function MarvelSnapBlockTracker() {
  const [cl, setCl] = React.useState(parseInt(localStorage.getItem("cl")) || 0),
    [clError, setClError] = React.useState(false),
    [lasts3, setLasts3] = React.useState(
      parseInt(localStorage.getItem("lasts3")) || 0
    ),
    [s3error, setS3error] = React.useState(false),
    [lasts4, setLasts4] = React.useState(
      parseInt(localStorage.getItem("lasts4")) || 0
    ),
    [s4error, setS4error] = React.useState(false);

  const [nexts3Block, setNextS3Block] = React.useState(null),
    [current40Block, setCurrent40Block] = React.useState(null),
    [current40BlockEnd, setCurrent40BlockEnd] = React.useState(null);

  const [clOfLastCache, setClOfLastCache] = React.useState(null),
    [s3InCurrentBlock, setS3InCurrentBlock] = React.useState(false),
    [s3BlockPosition, setS3BlockPosition] = React.useState(null),
    [s4InCurrentBlock, setS4InCurrentBlock] = React.useState(false);

  const findLastCache = (value) => {
    let workingValue = value - 1000;
    let counter = 0;
    let lastCache = null;
    while (lastCache === null) {
      if (
        workingValue >= 6 + counter * 48 &&
        workingValue <= 42 + counter * 48
      ) {
        if (
          workingValue >= 6 + counter * 48 &&
          workingValue < 18 + counter * 48
        ) {
          lastCache = 6 + counter * 48;
          break;
        } else if (
          workingValue >= 18 + counter * 48 &&
          workingValue < 30 + counter * 48
        ) {
          lastCache = 18 + counter * 48;
          break;
        } else if (
          workingValue >= 30 + counter * 48 &&
          workingValue < 42 + counter * 48
        ) {
          lastCache = 30 + counter * 48;
          break;
        } else {
          lastCache = 42 + counter * 48;
          break;
        }
      }

      counter += 1;
      if (counter > 1000) {
        console.log("Error finding block");
        break;
      }
    }
    return lastCache + 1000;
  };

  const find4Block = (value) => {
    let workingValue = value - 1000;
    let block = null;
    let counter = 0;
    while (block === null) {
      if (workingValue === 6 + counter * 48) {
        block = 0;
        break;
      }
      if (workingValue === 18 + counter * 48) {
        block = 1;
        break;
      }
      if (workingValue === 30 + counter * 48) {
        block = 2;
        break;
      }
      if (workingValue === 42 + counter * 48) {
        block = 3;
        break;
      }
      counter += 1;

      if (counter > 1000) {
        console.log("Error finding block");
        break;
      }
    }
    return block;
  };

  const find40Block = (value) => {
    let workingValue = value - 1006;
    let counter = 0;
    let blockMultiplier = null;
    while (blockMultiplier === null) {
      if (
        workingValue >= 480 * counter &&
        workingValue <= 480 * (counter + 1)
      ) {
        blockMultiplier = counter;
        break;
      }
      counter += 1;
      if (counter > 1000) {
        console.log("Error finding 40 block");
        break;
      }
    }
    return blockMultiplier;
  };

  React.useEffect(() => {
    let localClError = false;
    let localS3Error = false;
    let localS4Error = false;

    localStorage.setItem("cl", cl);
    localStorage.setItem("lasts3", lasts3);
    localStorage.setItem("lasts4", lasts4);

    if (cl < lasts3 || cl < lasts4) {
      setClError(true);
      localClError = true;
    } else {
      setClError(false);
    }

    if ((lasts3 >= 1006 && (lasts3 - 1000) % 6 === 0) || lasts3 === 0) {
      setS3error(false);
    } else {
      setS3error(true);
      localS3Error = true;
    }

    if ((lasts4 >= 1006 && (lasts4 - 1000) % 6 === 0) || lasts4 === 0) {
      setS4error(false);
    } else {
      setS4error(true);
      localS4Error = true;
    }

    if (
      localClError === false &&
      localS3Error === false &&
      localS4Error === false
    ) {
      let lastCache = findLastCache(cl);
      setClOfLastCache(lastCache);

      let fourtyBlock = find40Block(cl) * 480 + 1006;
      let fourtyBlockEnd = fourtyBlock + 480;

      if (lasts3 !== 0 && lasts3 >= 1006) {
        let currS3Block = find4Block(lasts3);
        setS3BlockPosition(currS3Block);
        let nextS3Block = 0;
        if (currS3Block === 0) {
          nextS3Block = lasts3 + 12 * 4;
        } else if (currS3Block === 1) {
          nextS3Block = lasts3 + 12 * 3;
        } else if (currS3Block === 2) {
          nextS3Block = lasts3 + 12 * 2;
        } else if (currS3Block === 3) {
          nextS3Block = lasts3 + 12 * 1;
        }
        setNextS3Block([
          nextS3Block,
          nextS3Block + 12,
          nextS3Block + 24,
          nextS3Block + 36,
        ]);
      } else {
        setNextS3Block([1006, 1018, 1030, 1042]);
      }

      setCurrent40Block(cl);
      setCurrent40BlockEnd(fourtyBlockEnd);
      if (lasts4 !== 0 && lasts4 >= 1006) {
        if (fourtyBlock <= lasts4 && fourtyBlockEnd >= lasts4) {
          setCurrent40Block(fourtyBlockEnd);
          setCurrent40BlockEnd(fourtyBlockEnd + 468);
        }
      }
    }
  }, [cl, lasts3, lasts4]);

  React.useEffect(() => {
    if (nexts3Block !== null) {
      let lastCache = findLastCache(cl);
      if (nexts3Block[0] > lastCache) {
        setS3InCurrentBlock(false);
      } else {
        setS3InCurrentBlock(true);
      }
    }
    if (current40Block !== null) {
      if (current40Block <= cl && current40BlockEnd >= cl) {
        setS4InCurrentBlock(true);
      } else {
        setS4InCurrentBlock(false);
      }
    }
  }, [cl, clOfLastCache, nexts3Block, current40Block, current40BlockEnd]);

  const FourBlockDisplay = ({
    blockA,
    blockB,
    blockC,
    blockD,
    alternateColour = false,
  }) => {
    return (
      <Stack spacing={1} direction={"row"}>
        <Paper elevation={alternateColour ? 0 : 1} sx={{ p: 1 }}>
          <Typography
            sx={{
              textDecoration: cl < blockA ? "none" : "line-through",
            }}
          >
            {blockA}
          </Typography>
        </Paper>
        <Paper elevation={alternateColour ? 1 : 0} sx={{ p: 1 }}>
          <Typography
            sx={{
              textDecoration: cl < blockB ? "none" : "line-through",
            }}
          >
            {blockB}
          </Typography>
        </Paper>
        <Paper elevation={alternateColour ? 0 : 1} sx={{ p: 1 }}>
          <Typography
            sx={{
              textDecoration: cl < blockC ? "none" : "line-through",
            }}
          >
            {blockC}
          </Typography>
        </Paper>
        <Paper elevation={alternateColour ? 1 : 0} sx={{ p: 1 }}>
          <Typography
            sx={{
              textDecoration: cl < blockD ? "none" : "line-through",
            }}
          >
            {blockD}
          </Typography>
        </Paper>
      </Stack>
    );
  };

  const ons3Change = (event) => {
    const value = event.target.value;
    setLasts3(parseInt(value));
  };

  const ons4Change = (event) => {
    const value = event.target.value;
    setLasts4(parseInt(value));
  };

  return (
    <>
      <Paper variant={"outlined"} sx={{ my: 2 }}>
        <Paper elevation={1} sx={{ borderRadius: 0, p: 1 }}>
          <Typography variant="h6">Card Predictor</Typography>
        </Paper>
        <Box p={2}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Stack direction={"column"} spacing={2} alignItems="flex-start">
                <TextField
                  label="Current Collection Level"
                  value={cl}
                  onChange={(e) => setCl(parseInt(e.target.value))}
                  type="number"
                  inputProps={{
                    min: 1006,
                  }}
                  helperText="Must be greater than 1006"
                  error={clError}
                />
                <TextField
                  label="Cl of last pool 3 card"
                  value={lasts3}
                  onChange={ons3Change}
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  helperText={
                    s3error
                      ? "Invalid value (please check that it is the same cl as the cache)"
                      : "Leave it at 0 if you haven't gotten an pool 3 card yet"
                  }
                  error={s3error}
                />
                <TextField
                  label="Cl of last pool 4 card"
                  value={lasts4}
                  onChange={ons4Change}
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  helperText={
                    s4error
                      ? "Invalid value (please check that it is the same cl as the cache)"
                      : "Leave it at 0 if you haven't gotten an pool 4 card yet"
                  }
                  error={s4error}
                />
              </Stack>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
              display="flex"
              direction={"column"}
              justifyContent="center"
            >
              <Box>
                <Typography gutterBottom>
                  You can expect your next Series 4 card between collection
                  levels:
                </Typography>

                <Stack
                  direction={"row"}
                  alignContent="center"
                  alignItems={"center"}
                >
                  <Chip label={current40Block} />
                  <Box
                    height={0}
                    width={32}
                    sx={{
                      mx: 2,
                      border: (theme) => `1px solid ${theme.palette.grey[500]}`,
                    }}
                  />
                  <Chip label={current40BlockEnd} />
                </Stack>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Typography gutterBottom>
                Your next Series 3 card will drop from one of the following
                caches:
              </Typography>
              {nexts3Block && (
                <FourBlockDisplay
                  blockA={nexts3Block[0]}
                  blockB={nexts3Block[1]}
                  blockC={nexts3Block[2]}
                  blockD={nexts3Block[3]}
                  currentCl={cl}
                />
              )}
            </Grid>
          </Grid>
          <Typography variant="overline" color="grey">
            Please make sure your last pool 3 and pool 4 values are correct
            otherwise there might be errors
          </Typography>
        </Box>
      </Paper>
      <CacheRerollArea
        cl={cl}
        clOfLastCache={clOfLastCache}
        s3InCurrentBlock={s3InCurrentBlock}
        s3BlockPosition={s3BlockPosition}
        s4InCurrentBlock={s4InCurrentBlock}
      />
    </>
  );
}

function CacheRerollArea({
  cl,
  clOfLastCache,
  s3InCurrentBlock,
  s3BlockPosition,
  s4InCurrentBlock,
}) {
  const [rolls, setRolls] = React.useState(1),
    [s3Complete, setS3Complete] = React.useState(false),
    [totalRolls, setTotalRolls] = React.useState(0);

  const [rewards, setRewards] = React.useState([]);
  const [rewardsTotals, setRewardsTotals] = React.useState({
    cards: { s3: 0, s4: 0, s5: 0 },
    credits: 0,
    gold: 0,
    tokens: 0,
    cosmetics: {
      variant: 0,
      avatar: 0,
      title: 0,
    },
  });
  const [pity, setPity] = React.useState(0);

  const resetRewards = () => {
    setRewards([]);
    setRewardsTotals({
      cards: { s3: 0, s4: 0, s5: 0 },
      credits: 0,
      gold: 0,
      tokens: 0,
      cosmetics: {
        variant: 0,
        avatar: 0,
        title: 0,
      },
    });
    setPity(0);
    setTotalRolls(0);
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <Grid container spacing={1}>
      <Grid
        container
        item
        spacing={1}
        xs={12}
        md={6}
        sx={{ alignContent: "center" }}
      >
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Alert severity="warning" sx={{ mb: 2 }}>
              Cache Rolling is not accuarate and needs an update.
            </Alert>
            <Stack direction="column" alignItems={"center"} spacing={2}>
              <Stack direction="column" spacing={1} alignItems="center">
                <img
                  src="/MarvelSnap/CollectorsReserve.png"
                  alt="Collectors Reserve"
                  height={"90"}
                  width="140"
                />
                <Typography>Collectors Reserve</Typography>
              </Stack>

              <ToggleButtonGroup value={s3Complete} color="success">
                <ToggleButton
                  value={true}
                  onClick={() => setS3Complete((prev) => !prev)}
                >
                  Series 3 Complete
                </ToggleButton>
              </ToggleButtonGroup>
              <TextField
                label="Rolls"
                type="number"
                value={rolls}
                onChange={(e) => setRolls(e.target.value)}
                inputProps={{ min: 1 }}
                helperText={`${numberWithCommas(
                  rolls * 12
                )} collection levels (${numberWithCommas(rolls * 12 + cl)})`}
              />
              <RollCacheButton
                rolls={rolls}
                s3Complete={s3Complete}
                setRewards={setRewards}
                setRewardsTotals={setRewardsTotals}
                pity={pity}
                setPity={setPity}
                setTotalRolls={setTotalRolls}
                resetData={resetRewards}
                rollsAlreadyDone={parseInt((cl - 1006) / 12) + 1}
                s3InCurrentBlock={s3InCurrentBlock}
                s3BlockPosition={s3BlockPosition}
                s4InCurrentBlock={s4InCurrentBlock}
              />
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Typography variant="h6">
              Total Rewards (Rolls: {totalRolls})
            </Typography>
            <Typography variant="body2">
              This would be a total of {numberWithCommas(totalRolls * 12)}{" "}
              levels, putting you at level {cl + totalRolls * 12}
            </Typography>
            <Typography variant="body2" sx={{ display: "block" }} gutterBottom>
              This would be a total of {numberWithCommas(totalRolls * 12 * 50)}{" "}
              credits (assuming each level is 50 credits)
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Cards</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="h6">S3</Typography>
                    <Typography>{rewardsTotals.cards.s3}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6">S4</Typography>
                    <Typography>
                      {rewardsTotals.cards.s4} (Pity reached: {pity})
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6">S5</Typography>
                    <Typography>{rewardsTotals.cards.s5}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6">Credits</Typography>
                <Typography>{rewardsTotals.credits}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6">Gold</Typography>
                <Typography>{rewardsTotals.gold}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6">Tokens</Typography>
                <Typography>{rewardsTotals.tokens}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Cosmetics</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="h6">Variant</Typography>
                    <Typography>{rewardsTotals.cosmetics.variant}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6">Avatar</Typography>
                    <Typography>{rewardsTotals.cosmetics.avatar}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6">Title</Typography>
                    <Typography>{rewardsTotals.cosmetics.title}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper variant="outlined" sx={{ p: 1 }}>
          <Typography variant="h6">Rewards</Typography>
          <Box sx={{ maxHeight: "78vh", overflowY: "scroll" }}>
            {rewards.map((reward, index) => (
              <RewardDisplay
                key={index}
                reward={reward}
                index={index}
                clOfLastCache={clOfLastCache}
              />
            ))}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

function RewardDisplay({ reward, index, clOfLastCache }) {
  const [color, setColor] = React.useState("secondary");

  React.useEffect(() => {
    if (reward.type === "card") {
      setColor("success");
    } else if (reward.type === "credits") {
      setColor("info");
    } else if (reward.type === "gold") {
      setColor("warning");
    } else if (reward.type === "tokens") {
      setColor("primary");
    } else if (reward.type === "cosmetic") {
      setColor("error");
    }
  }, [reward, color]);

  const rewardValue =
    reward.value === "s3"
      ? "Pool 3"
      : reward.value === "s4"
      ? "Pool 4"
      : reward.value === "s5"
      ? "Pool 5"
      : reward.value;

  return (
    <Paper elevation={index % 2 === 0 ? 0 : 1} sx={{ pl: 1, py: 0.5 }}>
      <Chip
        label={(index + 1) * 12 + clOfLastCache}
        size="small"
        sx={{ p: 1, mr: 2 }}
      />
      <Typography
        variant="overline"
        color={(theme) => theme.palette[color].main}
      >
        {reward.type}
        {" - "}
        {rewardValue}
      </Typography>
      <Typography variiant="overline" sx={{ display: "block" }}></Typography>
    </Paper>
  );
}

function RollCacheButton({
  rolls,
  s3Complete,
  setRewards,
  setRewardsTotals,
  setPity,
  setTotalRolls,
  resetData,
  rollsAlreadyDone,
  s3InCurrentBlock,
  s3BlockPosition,
  s4InCurrentBlock,
}) {
  const onClick = () => {
    resetData();
    // Set Variables
    let hasGottenS4 = s4InCurrentBlock,
      resets4 = false;

    let rewardsList = [];

    let totalRewards = {
      cards: { s3: 0, s4: 0, s5: 0 },
      credits: 0,
      gold: 0,
      tokens: 0,
      cosmetics: {
        variant: 0,
        avatar: 0,
        title: 0,
      },
    };

    // Set Variables using Data from the Players Collection Level and Track
    let gots3inBlock = s3InCurrentBlock,
      gotTokensinBlock = false,
      fourBlock = s3BlockPosition;

    let startingRoll = rollsAlreadyDone;
    let finalRolls = parseInt(rolls) + rollsAlreadyDone;

    // Loop through each cache
    for (let i = startingRoll; i < finalRolls; i++) {
      let reward = getReward(
        s3Complete,
        !gots3inBlock,
        !hasGottenS4,
        !gotTokensinBlock
      );

      // Check we are on the 38th roll and not gotten tokens if yes force tokens
      if ((i + 1) % 38 === 0) {
        if (!gotTokensinBlock) {
          let foundReward = weightedRandom(
            [200, 300, 400, 500, 600],
            [20, 20, 20, 20, 20]
          );
          reward = { type: "tokens", value: foundReward.item };
        }
      }

      // Check if we are on the 39th roll and not gotten a series 3 if yes force a series 3
      if ((i + 1) % 39 === 0) {
        if (!gots3inBlock) {
          reward = { type: "card", value: "s3" };
          if (s3Complete) {
            reward = { type: "tokens", value: 100 };
          }
        }
      }

      // Check is we are on the 40th roll and not gotten a series 4 if yes force a series 4
      if ((i + 1) % 40 === 0) {
        if (!hasGottenS4) {
          setPity((pity) => pity + 1);
          reward = { type: "card", value: "s4" };
        }
        resets4 = true;
      }

      // Check if we have gotten a series 3 card
      if (reward.type === "card" && reward.value === "s3") gots3inBlock = true;
      if (reward.type === "tokens" && parseInt(reward.value) === 100)
        gots3inBlock = true;
      // Check if we have gotten tokens
      if (reward.type === "tokens" && parseInt(reward.value) > 100)
        gotTokensinBlock = true;

      // Force tokens if we have not gotten a series 3 card or tokens in the last 3 caches
      if (fourBlock === 2) {
        if (!gots3inBlock || !gotTokensinBlock) {
          let foundReward = weightedRandom(
            [200, 300, 400, 500, 600],
            [20, 20, 20, 20, 20]
          );
          reward = { type: "tokens", value: foundReward.item };
          gotTokensinBlock = true;
        }
      }

      // Force a series 3 card if we have not gotten one in the last 4 caches
      if (fourBlock === 3) {
        if (!gots3inBlock) {
          reward = { type: "card", value: "s3" };
          if (s3Complete) {
            reward = { type: "tokens", value: 100 };
          }
        } else {
          if (!gotTokensinBlock) {
            let foundReward = weightedRandom(
              [200, 300, 400, 500, 600],
              [20, 20, 20, 20, 20]
            );
            reward = { type: "tokens", value: foundReward.item };
          }
        }

        fourBlock = -1;
        gots3inBlock = false;
        gotTokensinBlock = false;
      }

      // Check if we are on the have gotten a series 4 card
      if (reward.type === "card" && reward.value === "s4") hasGottenS4 = true;

      rewardsList.push(reward);

      if (reward.type === "card") {
        totalRewards.cards[reward.value] += 1;
      } else if (reward.type === "credits") {
        totalRewards.credits += parseInt(reward.value);
      } else if (reward.type === "gold") {
        totalRewards.gold += parseInt(reward.value);
      } else if (reward.type === "tokens") {
        totalRewards.tokens += parseInt(reward.value);
      } else if (reward.type === "cosmetic") {
        totalRewards.cosmetics[reward.value] += 1;
      }

      if (resets4) {
        hasGottenS4 = false;
        resets4 = false;
      }

      setTotalRolls((prev) => prev + 1);
      fourBlock += 1;
    }

    setRewards([...rewardsList]);

    setRewardsTotals({ ...totalRewards });
  };

  return (
    <>
      <LoadingButton variant="contained" onClick={onClick}>
        Roll Cache
      </LoadingButton>
    </>
  );
}
